// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_FLrTo,.row_AAdbs{display:flex}.container_FLrTo{background-color:var(--color-white);flex-direction:column}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_FLrTo",
	"row": "row_AAdbs"
};
module.exports = ___CSS_LOADER_EXPORT___;
