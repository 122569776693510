// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_hSuMH{flex-direction:column}.container_hSuMH,.items_YtFIA{display:flex}.items_YtFIA.itemsGrid_Xf\\+Hf{display:grid;grid-column-gap:1rem;-moz-column-gap:1rem;column-gap:1rem;grid-row-gap:1rem;row-gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_hSuMH",
	"items": "items_YtFIA",
	"itemsGrid": "itemsGrid_Xf+Hf"
};
module.exports = ___CSS_LOADER_EXPORT___;
