// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".copy_vX6YZ{color:var(--color-black-900);font-family:\"GerstnerProgrammFSL\"!important;font-size:.875rem!important;font-weight:700!important;line-height:1rem!important;overflow:hidden;text-overflow:ellipsis;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"copy": "copy_vX6YZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
