import { render, staticRenderFns } from "./DataTableCellHeader.vue?vue&type=template&id=7e408662"
import script from "./DataTableCellHeader.vue?vue&type=script&setup=true&lang=ts"
export * from "./DataTableCellHeader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./DataTableCellHeader.vue?vue&type=style&index=0&id=7e408662&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TickBox: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,DataTableCellHeaderCopy: require('/app/components/DataTable/components/DataTableCellHeaderCopy.vue').default,ChromeTooltip: require('/app/components/Chrome/ChromeTooltip.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default})
