// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".circleContainer_n-KxT{cursor:pointer;display:inline-flex;flex-direction:row-reverse;height:2rem}.circleContainer_n-KxT>:not(:last-child){margin-left:-.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"circleContainer": "circleContainer_n-KxT"
};
module.exports = ___CSS_LOADER_EXPORT___;
