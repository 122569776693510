// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cell_IcV2\\+ button{max-width:100%;text-align:left}.cell_IcV2\\+.textAlignEnd_ZooHy button{text-align:right}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": "cell_IcV2+",
	"textAlignEnd": "textAlignEnd_ZooHy"
};
module.exports = ___CSS_LOADER_EXPORT___;
