var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('portal', {
    attrs: {
      "to": "chrome-notification-layer-4"
    }
  }, [_c('ChromeNotification', {
    "class": _vm.$style.container,
    attrs: {
      "keepMounted": false,
      "color": "--color-white",
      "visible": _vm.open
    },
    on: {
      "click-close": _setup.close,
      "click-outside": _setup.close
    }
  }, [_c('Composition', {
    attrs: {
      "explodeHeight": "",
      "loading": !_setup.hasData,
      "loadingEnabled": true,
      "loadingColor": "--color-black-50"
    },
    scopedSlots: _vm._u([{
      key: "layouts",
      fn: function fn() {
        return [_c('CompositionLayoutSingle', {
          attrs: {
            "omitPadding": ""
          },
          scopedSlots: _vm._u([{
            key: "container",
            fn: function fn() {
              return [_c('WidgetLayout', {
                attrs: {
                  "colorBackground": "--color-black-50"
                },
                scopedSlots: _vm._u([{
                  key: "widgetRows",
                  fn: function fn() {
                    return [_c('WidgetLayoutRowCellSingle', {
                      scopedSlots: _vm._u([{
                        key: "widget",
                        fn: function fn() {
                          return [_c('WidgetBlank', {
                            attrs: {
                              "colorBackground": "--color-white",
                              "omitClickMore": ""
                            },
                            scopedSlots: _vm._u([{
                              key: "container",
                              fn: function fn() {
                                return [_c('Copy', {
                                  attrs: {
                                    "type": "header",
                                    "size": "medium",
                                    "color": "--color-black-900"
                                  }
                                }, [_vm._v(" " + _vm._s(_setup.t("PORTFOLIO_VIEW_TENANCIES_SAVED_FILTER_BOOKMARKS")) + " ")])];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }])
                    }), _vm._v(" "), _c('WidgetLayoutRowCellSingle', {
                      scopedSlots: _vm._u([{
                        key: "widget",
                        fn: function fn() {
                          return [_c('WidgetBlank', {
                            attrs: {
                              "colorBackground": "--color-white",
                              "omitPadding": "",
                              "omitClickMore": ""
                            },
                            scopedSlots: _vm._u([{
                              key: "container",
                              fn: function fn() {
                                var _setup$filters;
                                return [_c('SimpleTable', [_c('SimpleTableRow', {
                                  attrs: {
                                    "hoverEffectDisable": ""
                                  }
                                }, [_c('SimpleTableCell', [_vm._v(" " + _vm._s(_setup.t("NAME")) + " ")]), _vm._v(" "), _c('SimpleTableCell', [_vm._v(" " + _vm._s(_setup.t("FOLLOWING")) + " ")]), _vm._v(" "), _c('SimpleTableCell', [_vm._v(" " + _vm._s(_setup.t("DEFAULT")) + " ")])], 1), _vm._v(" "), !_setup.filterBookmarks.loading.value && !((_setup$filters = _setup.filters) !== null && _setup$filters !== void 0 && _setup$filters.length) ? _c('SimpleTableRow', [_c('SimpleTableCell', {
                                  attrs: {
                                    "colSpan": "3"
                                  }
                                }, [_c('PushButton', {
                                  attrs: {
                                    "color": "--color-transparent",
                                    "size": "medium"
                                  },
                                  on: {
                                    "click": function click() {
                                      return _setup.emit('edit');
                                    }
                                  },
                                  scopedSlots: _vm._u([{
                                    key: "copy",
                                    fn: function fn() {
                                      return [_c('Copy', {
                                        attrs: {
                                          "size": "medium",
                                          "type": "label",
                                          "color": "--color-black-900"
                                        }
                                      }, [_vm._v(" " + _vm._s(_setup.t("PORTFOLIO_VIEW_TENANCIES_NO_FILTER_BOOKMARKS")) + " ")])];
                                    },
                                    proxy: true
                                  }], null, false, 2824928140)
                                })], 1)], 1) : _vm._e(), _vm._v(" "), _vm._l(_setup.filters, function (filter) {
                                  return _c(_setup.TableRowTenanciesSavedFilter, {
                                    key: filter.id,
                                    attrs: {
                                      "filter": filter,
                                      "defaultFilters": _setup.defaultFilters
                                    },
                                    on: {
                                      "open-filter": function openFilter() {
                                        return _setup.openFilter(filter);
                                      },
                                      "edit-filter": function editFilter() {
                                        return _setup.emit('edit', filter);
                                      },
                                      "savedFilterDeleted": function savedFilterDeleted() {
                                        return _setup.emit('savedFilterDeleted');
                                      }
                                    }
                                  });
                                })], 2)];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }])
                    }), _vm._v(" "), _c('WidgetLayoutRowCellSingle', {
                      scopedSlots: _vm._u([{
                        key: "widget",
                        fn: function fn() {
                          return [_c('WidgetBlank', {
                            attrs: {
                              "colorBackground": "--color-white",
                              "omitClickMore": ""
                            },
                            scopedSlots: _vm._u([{
                              key: "container",
                              fn: function fn() {
                                return [_c('PushButton', {
                                  attrs: {
                                    "size": "medium",
                                    "color": "--color-transparent",
                                    "underline": ""
                                  },
                                  on: {
                                    "click": _setup.close
                                  },
                                  scopedSlots: _vm._u([{
                                    key: "iconBefore",
                                    fn: function fn() {
                                      return [_c('Icon', {
                                        attrs: {
                                          "id": "close",
                                          "size": "medium",
                                          "color": "--color-black-900"
                                        }
                                      })];
                                    },
                                    proxy: true
                                  }, {
                                    key: "copy",
                                    fn: function fn() {
                                      return [_c('Copy', {
                                        attrs: {
                                          "type": "label",
                                          "size": "medium",
                                          "color": "--color-black-900"
                                        }
                                      }, [_vm._v(_vm._s(_setup.t("BUTTON_CLOSE")))])];
                                    },
                                    proxy: true
                                  }])
                                })];
                              },
                              proxy: true
                            }])
                          })];
                        },
                        proxy: true
                      }])
                    })];
                  },
                  proxy: true
                }])
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };