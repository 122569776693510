
export default {
  inheritAttrs: false,

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    type: {
      type: String,
      required: true
    },

    size: {
      type: String,
      required: true
    },

    color: {
      type: String,
      required: true
    },

    colorLoader: {
      type: String,
      default: "--color-black-50"
    }
  }
};
