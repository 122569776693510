// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".clickCell_4ArXB{cursor:pointer}.clickCell_4ArXB>div:hover{-webkit-text-decoration:underline;text-decoration:underline}.clickRow_FTHMk{cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickCell": "clickCell_4ArXB",
	"clickRow": "clickRow_FTHMk"
};
module.exports = ___CSS_LOADER_EXPORT___;
