import type { QueryFilterCollection, QuerySortLoose } from "~/helpers/apollo/types";

export const getFilterColor = (active: boolean) => `--color-${active ? "purple" : "black"}-900`;

export type FilterBookmark = {
  id: string;
  name: string;
  notifyUserOnChangesToOutput: boolean;
  filters: QueryFilterCollection;
  isDefault: boolean;
  columns?: string[] | null;
  sort?: QuerySortLoose | null;
};

export type TenantStatus = "upcomingTenant" | "noUpcomingTenant";

export const tenancyStatusIds = ["vacant", "occupiedTerminated", "occupiedNotTerminated"] as const;
export type TenancyStatus = (typeof tenancyStatusIds)[number];
