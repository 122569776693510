// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".round_7LoQG{align-items:center;background-color:var(--color-background);border:1px solid var(--color-border);border-radius:50%;display:flex;height:2.25rem;justify-content:center;width:2.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"round": "round_7LoQG"
};
module.exports = ___CSS_LOADER_EXPORT___;
