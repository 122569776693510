// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dragColumn_rRNP4{align-items:center;border:1px solid var(--color-supportive-3-400);border-radius:var(--border-radius-large);cursor:pointer;display:flex;flex-direction:row;height:2rem;margin-bottom:.5rem;overflow:hidden}.dragColumn_rRNP4:active{cursor:grabbing}.dragIcon_Cil0E{cursor:grabbing;height:100%;justify-content:center;width:1.5rem}.dragIcon_Cil0E,.removeIcon_QTIqh{align-items:center;display:flex;padding:.25rem}.removeIcon_QTIqh{cursor:pointer;margin-left:auto;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.removeIcon_QTIqh:hover{transform:scale(1.15)}.dragGhost_W\\+qsk{opacity:.2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragColumn": "dragColumn_rRNP4",
	"dragIcon": "dragIcon_Cil0E",
	"removeIcon": "removeIcon_QTIqh",
	"dragGhost": "dragGhost_W+qsk"
};
module.exports = ___CSS_LOADER_EXPORT___;
