// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerRight_Btgzl{display:flex;flex:1;flex-direction:column}.dropdownButton_XEnCk{min-height:2.5rem}.buttons_64uli{margin-left:auto}.control_1nrxQ{cursor:pointer;display:flex;transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.control_1nrxQ:hover{transform:scale(1.15)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerRight": "headerRight_Btgzl",
	"dropdownButton": "dropdownButton_XEnCk",
	"buttons": "buttons_64uli",
	"control": "control_1nrxQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
