import { useQuery } from "@vue/apollo-composable";
import { TenancyFilterBookmarkDocument } from "~/graphql/generated/graphql";
export var useTenancyFilterBookmarkQuery = function useTenancyFilterBookmarkQuery(filterId, enabled) {
  var query = useQuery(TenancyFilterBookmarkDocument, function () {
    return {
      id: filterId.value
    };
  }, function () {
    return {
      enabled: enabled.value && !!filterId.value,
      keepPreviousResult: true
    };
  });
  var result = computed(function () {
    var _query$result$value;
    return (_query$result$value = query.result.value) === null || _query$result$value === void 0 ? void 0 : _query$result$value.assetManagementTenancyFilterBookmark;
  });
  var loading = computed(function () {
    return query.loading.value;
  });
  return {
    result: result,
    loading: loading
  };
};